import * as echarts from "echarts/core";
import { forwardRef, useMemo, type ComponentProps } from "react";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
} from "echarts/components";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import type { EChartsOption } from "echarts";
import { Grid, Typography, useColorScheme, useTheme } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import colors from "../../tokens/colors";
import FeedbackMessage from "../FeedbackMessage/FeedbackMessage";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import TooltipFormatter from "./TooltipFormatter";

const translationStrings = [
  "Cost Chart",
  "Loading...",
  "Nothing here yet!",
  "Oops! Something went wrong on our end. Please try again in a few minutes.",
  "No data available to display. Once data is added, it will appear here.",
  "Cost (NOK)", // ISSUE - NOK is static while it should be dynamic
  "Days",
  "Error",
  "DSV Cost",
  "AFE Cost",
] as const;

export interface WellboreCostGraphProps {
  costs?: {
    dsvCost: [duration: number, cost: number][];
    afeCost: [duration: number, cost: number][];
    dsvPredictiveCost: [duration: number, cost: number][];
    afeActivities: { name?: string; xAxis: number }[][];
  };
  currency?: string;
  isLoading?: boolean;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
}

echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

function WellboreCostGraph(
  {
    costs,
    currency,
    translations,
    isLoading,
    isError = false,
    renderer,
  }: WellboreCostGraphProps,
  ref: ComponentProps<typeof EChartsRenderer>["ref"]
) {
  const theme = useTheme();
  const { colorScheme } = useColorScheme();
  const tooltipContainer = useTooltipContainer();
  const t = useMemo(
    () => ({ ...defaultTranslations(translationStrings), ...translations }),
    [translations]
  );

  const options: EChartsOption = useMemo(() => {
    const seriesMetadata = {
      dsvCost: {
        title: t["DSV Cost"],
        color: colors["alphaLightGreen"][900],
      },
      afeCost: {
        title: t["AFE Cost"],
        color: colors["alphaBlue"][900],
      },
    };

    return {
      title: {
        text: t["Cost Chart"],
        textStyle: {
          fontWeight: 300,
          fontSize: 20,
          fontFamily: "Inter",
          lineHeight: 24,
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
        },
      },
      tooltip: {
        trigger: "axis",
        borderWidth: 0,
        backgroundColor: "transparent",
        extraCssText: "box-shadow: none;",
        formatter: (params) => {
          return tooltipContainer(TooltipFormatter, params, { currency });
        },
      },
      legend: {
        show: true,
        data: [
          {
            name: seriesMetadata.dsvCost.title,
            icon: "line",
            itemStyle: {
              borderColor: seriesMetadata.dsvCost.color,
              borderWidth: 2,
            },
          },
          {
            name: seriesMetadata.afeCost.title,
            icon: "line",
            itemStyle: {
              borderColor: seriesMetadata.afeCost.color,
              borderWidth: 2,
            },
          },
        ],
        right: 20,
        itemWidth: 16,
        itemHeight: 16,
        formatter: (name) => {
          return `{name|${name}}`;
        },
        textStyle: {
          rich: {
            name: {
              overflow: "truncate",
              fontWeight: 300,
              fontSize: 10,
              lineHeight: 15,
              fontFamily: "Inter",
              color:
                colorScheme === "dark"
                  ? colors["grey"][100]
                  : colors["grey"][800],
            },
          },
        },
        inactiveColor: "rgba(0,0,0,0.2)",
      },
      xAxis: {
        name: t["Days"],
        showGrid: false,
        splitLine: {
          show: false,
        },
        nameLocation: "middle",
        nameGap: 25,
        min: 0,
        type: "value",
        axisLine: { onZero: false, show: false },
      },
      yAxis: {
        name: t["Cost (NOK)"],
        nameLocation: "middle",
        nameGap: 70,
        min: 0,
        type: "value",
        axisLine: { onZero: false, show: false },
        splitLine: {
          lineStyle: {
            type: "dotted",
          },
        },
      },
      grid: {
        left: 45,
        right: 20,
        containLabel: true,
      },
      series: [
        {
          data: costs?.dsvCost,
          name: seriesMetadata.dsvCost.title,
          type: "line" as const,
          itemStyle: { color: seriesMetadata.dsvCost.color },
          lineStyle: {
            width: 2,
            color: seriesMetadata.dsvCost.color,
          },
          showSymbol: false,
        },
        {
          data: costs?.afeCost,
          name: seriesMetadata.afeCost.title,
          type: "line" as const,
          itemStyle: { color: seriesMetadata.afeCost.color },
          lineStyle: {
            width: 2,
            color: seriesMetadata.afeCost.color,
          },
          showSymbol: false,
          markArea: {
            silent: true,
            label: {
              color:
                colorScheme === "dark"
                  ? colors["grey"][300]
                  : colors["grey"][600],
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 300,
              rotate: -90,
              position: ["50%", 10],
              fontFamily: "Inter",
            },
            data: costs?.afeActivities.map(
              ([activityStart, activityEnd], index) => [
                {
                  ...activityStart,
                  itemStyle: {
                    color:
                      index % 2 === 0
                        ? colors["alphaLightGreen"][100]
                        : colors["alphaLightGreen"][50],
                  },
                },
                activityEnd,
              ]
            ),
          },
        },
        {
          data: costs?.dsvPredictiveCost,
          name: `Predictive ${seriesMetadata.dsvCost.title}`,
          type: "line" as const,
          itemStyle: {
            color: seriesMetadata.dsvCost.color,
            borderType: "dotted",
          },
          lineStyle: {
            width: 2,
            type: "dotted",
            color: seriesMetadata.dsvCost.color,
          },
          showSymbol: false,
        },
      ],
    };
  }, [colorScheme, currency, costs, t, tooltipContainer]);

  if (
    !isLoading &&
    (isError ||
      !costs ||
      (costs.dsvCost.length === 0 &&
        costs.afeCost.length === 0 &&
        costs.dsvPredictiveCost.length === 0))
  ) {
    return (
      <Grid height="100%" container direction="column">
        <Grid>
          <Typography level="h4" fontWeight="sm">
            {t["Cost Chart"]}
          </Typography>
        </Grid>
        <Grid
          flexGrow={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isError ? (
            <FeedbackMessage
              title={t["Error"]}
              description={
                t[
                  "Oops! Something went wrong on our end. Please try again in a few minutes."
                ]
              }
              isError
            />
          ) : (
            <FeedbackMessage
              title={t["Nothing here yet!"]}
              description={
                t[
                  "No data available to display. Once data is added, it will appear here."
                ]
              }
            />
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <EChartsRenderer
      ref={ref}
      notMerge={true}
      lazyUpdate={true}
      echarts={echarts}
      option={options}
      style={{ height: "100%", width: "100%" }}
      loadingOption={{
        text: t["Loading..."],
        color: "gray",
        textColor: theme.palette.common.black,
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: true,
        spinnerRadius: 10,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "Inter",
      }}
      showLoading={isLoading}
      opts={{ renderer }}
    />
  );
}

export default forwardRef(WellboreCostGraph);

import { Checkbox, checkboxClasses, type Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";
import colors from "../../tokens/colors";
import type { ComponentProps } from "react";

const colorMap = {
  primary: "blue",
  info: "grey",
  error: "red",
  success: "green",
  warning: "yellow",
} as const;

type CheckboxColorType = Extract<
  NonNullable<ComponentProps<typeof Checkbox>["color"]>,
  keyof typeof colorMap
>;

const checkboxCustomizations: Components<Theme> = {
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        padding: "0",
        [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
          color: colors[colorMap[ownerState.color as CheckboxColorType]][600],
          ...(ownerState.color === "info" && {
            color: theme.palette.info.contrastText,
          }),
        },
        ...(ownerState.size === "small" && {
          "& .MuiSvgIcon-root": {
            fontSize: "1.25rem",
          },
        }),
        ...(ownerState.size === "medium" && {
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
          },
        }),
        ...(ownerState.size === "large" && {
          "& .MuiSvgIcon-root": {
            fontSize: "1.75rem",
          },
        }),
      }),
    },
  },
};

export default checkboxCustomizations;

import { radioClasses, type Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";

const radioCustomizations: Components<Theme> = {
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
      color: "info",
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const color = (() => {
          if (!ownerState.color) return theme.palette.info.contrastText;
          return {
            primary: theme.palette.primary.main,
            info: theme.palette.info.contrastText,
            error: theme.palette.error.main,
            success: theme.palette.success.main,
            warning: theme.palette.warning.main,
            secondary: theme.palette.info.contrastText,
            default: theme.palette.info.contrastText,
          }[ownerState.color];
        })();
        return {
          padding: "0",
          [`&.${radioClasses.checked}`]: {
            color,
          },
        };
      },
    },
  },
};

export default radioCustomizations;

import { type Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";

const formControlCustomizations: Components<Theme> = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        gap: theme.spacing(1),
        margin: 0,
        userSelect: "none",
      }),
    },
  },
};

export default formControlCustomizations;

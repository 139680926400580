import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, type Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";
import { fontSize } from "../../tokens/typography";
import colors from "../../tokens/colors";
import type { ComponentProps } from "react";

const COLOR_MAPS = {
  active: {
    primary: (theme: Theme) => theme.palette.primary.dark,
    secondary: (theme: Theme) => theme.palette.secondary.dark,
    info: colors.grey[300],
    error: (theme: Theme) => theme.palette.error.dark,
    success: (theme: Theme) => theme.palette.success.dark,
    warning: (theme: Theme) => theme.palette.warning.dark,
    default: (theme: Theme) => theme.palette.info.contrastText,
  },
  hover: {
    primary: colors.blue[600],
    secondary: colors.purple[600],
    info: colors.grey[200],
    error: colors.red[600],
    success: colors.green[600],
    warning: colors.yellow[600],
    default: colors.grey[200],
  },
};

const chipCustomizations: Components<Theme> = {
  MuiChip: {
    defaultProps: {
      variant: "outlined",
      color: "info",
      deleteIcon: (
        <FontAwesomeIcon icon={faXmark} width="0.75rem" height="0.75rem" />
      ),
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const {
          color: chipColor,
          variant: chipVariant,
          size: chipSize,
        } = ownerState;

        const getColor = (
          colorMap: Record<
            NonNullable<ComponentProps<typeof Chip>["color"]>,
            string | ((theme: Theme) => string)
          >
        ) => {
          const color = chipColor && colorMap[chipColor];
          return typeof color === "function"
            ? color(theme)
            : color || colorMap.default;
        };

        const isInfoColor = chipColor === "info";
        const isWarningColor = chipColor === "warning";
        const isFilledVariant = chipVariant === "filled";
        const isOutlinedVariant = chipVariant === "outlined";
        const isSmallSize = chipSize === "small";

        const baseStyles = {
          "& .MuiChip-deleteIcon, .MuiChip-icon": {
            fontSize: chipSize === "small" ? fontSize.sm : fontSize.md,
            color: "inherit",
          },
          ...(isSmallSize && { height: "1.25rem" }),
          ...(chipSize === "medium" && { height: "1.5rem" }),
        };

        const filledStyles = isFilledVariant
          ? {
              color:
                isInfoColor || isWarningColor
                  ? colors.grey[800]
                  : theme.palette.common.white,
              ...(isInfoColor && {
                backgroundColor: theme.palette.common.white,
              }),
              "&.MuiChip-clickable:hover": {
                backgroundColor: getColor(COLOR_MAPS.hover),
                color:
                  isInfoColor || isWarningColor
                    ? colors.grey[800]
                    : theme.palette.common.white,
              },
              "&.MuiChip-clickable:active": {
                backgroundColor: getColor(COLOR_MAPS.active),
              },
            }
          : {};

        const outlinedStyles = isOutlinedVariant
          ? {
              ...(isInfoColor && {
                borderColor: theme.palette.info.light,
                color: theme.palette.info.contrastText,
              }),
              "&.MuiChip-clickable:hover": {
                backgroundColor: getColor(COLOR_MAPS.hover),
                color:
                  isInfoColor || isWarningColor
                    ? colors.grey[800]
                    : theme.palette.common.white,
              },
              "&.MuiChip-clickable:active": {
                backgroundColor: getColor(COLOR_MAPS.active),
              },
            }
          : {};

        return {
          ...baseStyles,
          ...filledStyles,
          ...outlinedStyles,
        };
      },
      label: ({ theme }) => ({
        paddingInline: theme.spacing(2),
      }),
    },
  },
};

export default chipCustomizations;

import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Provider,
  TreeItem2Root,
  type TreeViewBaseItem,
  type UseTreeItem2Parameters,
} from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import * as React from "react";
import { getItemChildIds } from "./helpers";
import { Checkbox, FormControlLabel } from "@mui/material";

interface TreeViewBaseItemWithParents extends TreeViewBaseItem {
  children?: TreeViewBaseItemWithParents[];
  parents?: string[];
}

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {
  onItemClick: (checked: boolean, itemId: string) => void;
  selectedItems: string[];
}

export const CustomTreeItem = React.forwardRef(function CustomTreeItem2(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    onItemClick,
    selectedItems,
    ...other
  } = props;

  const {
    getCheckboxProps,
    getContentProps,
    getGroupTransitionProps,
    getIconContainerProps,
    getLabelProps,
    getRootProps,
    publicAPI,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  // Get the full item, so we have access to the item parents and children
  const item = publicAPI.getItem(itemId) as TreeViewBaseItemWithParents;
  let indeterminate = false;

  // If the item has children, it's possible for the checkbox state to be indeterminate
  if (item.children) {
    // Get all of the item's children's IDs (recursively)
    const allChildIds = getItemChildIds(item);

    // Check if some children are selected
    const selectedChildIds = allChildIds.filter((childId) =>
      selectedItems.includes(childId)
    );

    // Some children are selected but not all, mark as indeterminate
    if (
      selectedChildIds.length > 0 &&
      selectedChildIds.length < allChildIds.length
    ) {
      indeterminate = true;
    }

    // Check if any selected item starts with this item's ID but is not a child
    const parentPrefixWithoutLastChar = `${itemId.slice(0, -1)}:`; // This assumes that the parent ID is prefixed to the child IDs
    const selectedWithPrefix = selectedItems.filter(
      (selectedItem) =>
        selectedItem.includes(parentPrefixWithoutLastChar) &&
        !allChildIds.includes(selectedItem)
    );

    // If we have such items, mark the parent as indeterminate
    if (
      selectedWithPrefix.length > 0 &&
      selectedChildIds.length !== allChildIds.length
    ) {
      indeterminate = true;
    }
  }

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root
        {...getRootProps(other)}
        aria-expanded={status.expanded}
        data-testid={itemId}
      >
        <TreeItem2Content {...getContentProps()}>
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <FormControlLabel
            control={
              <Checkbox
                color="info"
                {...getCheckboxProps()}
                onChange={(e) => {
                  const { checked } = e.target;
                  e.stopPropagation();
                  onItemClick(checked, itemId);
                }}
                indeterminate={indeterminate}
                aria-checked={
                  indeterminate ? "mixed" : getCheckboxProps().checked
                }
              />
            }
            label={getLabelProps().children}
          />
        </TreeItem2Content>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { BarChart, CustomChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  type ComponentProps,
  type MutableRefObject,
} from "react";
import type { EchartsEventParams } from "../../components/shared/types/EchartsEventParams";
import { type EChartsOption } from "echarts";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { makeSeries, type OperationTypes } from "./helpers/makeSeries";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import { Box, useTheme } from "@mui/joy";
import ActivityChartLoading from "./ActivityChartLoading";
import ActivityChartMessage from "./ActivityChartMessage";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import { isRef } from "../../helpers/utility";
import { OPERATION_TYPES } from "./constants";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import AssetActivityChartTooltip from "./AssetActivityChartTooltip";
import useColors from "./helpers/useColors";
import timeFormatter from "../../helpers/ECharts/timeFormatter";
import generateFacilityWellboreId from "./helpers/generateFacilityWellboreId";
import removeConsecutiveTitles from "./helpers/removeConsecutiveTitles";

const translationStrings = [
  "Loading...",
  "Completion",
  "Plug Abandon",
  "Drilling",
  "Workover",
  "Interruption",
  "Formation Evaluation",
  "Not Specified",
  "Moving",
  "Error",
  "Nothing here yet!",
  "It seems that there are currently no planned activities. They will be displayed here once scheduled.",
  "We encountered an issue while trying to display your activity schedule. Please refresh the page or try again later.",
] as const;

type Operation = {
  type: OperationTypes;
  activities: {
    wellboreId: string;
    startDate: Date;
    endDate: Date;
  }[];
};
type Wellbore = {
  id: string;
  name: string;
  isTitle?: boolean;
};

export type ActivityData = {
  facilityName: string;
  operations: Operation[];
  wellbores: Wellbore[];
};

export type AssetActivityChartProps = {
  title?: string;
  activityData?: ActivityData[];
  isLoading: boolean;
  onClick: (
    event: Event,
    data: [startDate: Date, endDate: Date, wellboreId: string]
  ) => void;
  startDate: Date;
  endDate: Date;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
  hideLegend?: boolean;
};

function AssetActivityChart(
  {
    isLoading = true,
    onClick,
    activityData = [],
    startDate,
    translations,
    isError = false,
    renderer,
    title,
    hideLegend = false,
    endDate,
  }: AssetActivityChartProps,
  ref: ComponentProps<typeof EChartsRenderer>["ref"]
) {
  const theme = useTheme();
  const t = useMemo(
    () => ({
      ...defaultTranslations(translationStrings),
      ...translations,
    }),
    [translations]
  );
  const echartsComponentRef = useRef<ReactEChartsCore | null>(null);
  const previousDateRef = useRef<Date | null>(null);

  const tooltipContainer = useTooltipContainer();

  useEffect(() => {
    if (isRef(ref)) {
      (ref as MutableRefObject<unknown>).current = echartsComponentRef.current;
    }
  }, [ref]);

  const colors = useColors();

  const operationTypeLegendNames = useMemo(
    () => ({
      Drilling: t["Drilling"],
      Completion: t["Completion"],
      FormationEvaluation: t["Formation Evaluation"],
      Moving: t["Moving"],
      Interruption: t["Interruption"],
      Workover: t["Workover"],
      PlugAbandon: t["Plug Abandon"],
      NotAvailable: t["Not Specified"],
    }),
    [t]
  );

  const operations = useMemo(() => {
    if (!activityData.length) return [];
    const uniqueOperations = activityData.flatMap((facility) =>
      facility.operations.map((operation) => ({
        ...operation,
        activities: operation.activities.map((activity) => ({
          ...activity,
          wellboreId: generateFacilityWellboreId(
            facility.facilityName,
            activity.wellboreId
          ),
        })),
      }))
    );

    return uniqueOperations.sort(
      (a, b) =>
        OPERATION_TYPES.indexOf(a.type) - OPERATION_TYPES.indexOf(b.type)
    );
  }, [activityData]);

  // Add a custom row inside wellbores array to render the title easier
  const wellbores = useMemo(() => {
    if (!activityData.length) return [];
    return activityData.flatMap((facility) => [
      {
        id: `title-${facility.facilityName}`,
        isTitle: true,
        name: facility.facilityName,
      },
      ...facility.wellbores.map((wellbore) => ({
        ...wellbore,
        id: generateFacilityWellboreId(facility.facilityName, wellbore.id),
      })),
    ]);
  }, [activityData]);

  const options: EChartsOption = useMemo(() => {
    const shouldEnableDataZoom = wellbores.length > 10 && !isError;
    const maxVisibleRows = 10;
    const numberOfRows = wellbores.length;
    const uniqueOperations = [...new Set(operations.map((op) => op.type))];
    const legendColors = uniqueOperations.map((op) => colors.operations[op]);
    const displayFullLegend = wellbores.length === 0;

    // Remove 1% of space due to space between the rows in order to show max 10 rows correctly
    const endPercentage =
      numberOfRows > maxVisibleRows
        ? (maxVisibleRows / numberOfRows) * 100 - 1
        : 100;

    return {
      title: {
        text: title,
        left: "left",
        textStyle: {
          fontFamily: "Inter",
          fontSize: theme.fontSize.xl,
          fontWeight: theme.fontWeight.sm as number,
        },
      },
      legend: {
        selectedMode: wellbores.length > 2 ? true : false,
        textStyle: {
          fontFamily: "Inter",
          fontSize: theme.fontSize.xs,
          fontWeight: theme.fontWeight.sm as number,
        },
        bottom: 30,
        padding: [10, 15, 10, 15],
        itemGap: 30,
        show: !hideLegend,
        left: "right",
        top: "top",
        itemHeight: 16,
        itemWidth: 16,
        icon: "path://M100,100 h200 a20,20 0 0 1 20,20 v200 a20,20 0 0 1 -20,20 h-200 a20,20 0 0 1 -20,-20 v-200 a20,20 0 0 1 20,-20 z",
      },
      tooltip: {
        show: false, // is handled by the mouseover event
        trigger: "item",
        position: function (point, _params, _dom, _rect, size) {
          const tooltipWidth = size.contentSize[0];
          const tooltipHeight = size.contentSize[1];
          const x = point[0] - tooltipWidth / 2;
          const y = point[1] - tooltipHeight - 20;
          return [x, y];
        },
        borderWidth: 0,
        backgroundColor: "transparent",
        formatter: (params) =>
          tooltipContainer(AssetActivityChartTooltip, params),
      },
      color: legendColors,
      grid: {
        left: 120,
        right: 30,
        bottom: 10,
        containLabel: true,
      },
      xAxis: {
        type: "time",
        minInterval: 3600 * 1000 * 24,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 40,
          show: wellbores.length === 0 ? false : true,
          formatter: (value) =>
            timeFormatter(value, startDate, endDate, previousDateRef),
          rich: {
            a: {
              fontWeight: "bold",
            },
          },
          showMinLabel: true,
          showMaxLabel: true,
          alignMinLabel: "left",
          alignMaxLabel: "right",
          hideOverlap: true,
          padding: [0, 15],
        },
        splitLine: { show: false },
        z: 12,
      },
      yAxis: {
        inverse: true, // Invert the y-axis to have the first row at the top
        data: wellbores.map((_, index) => index),
        type: "category",
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      dataZoom: shouldEnableDataZoom
        ? [
            {
              type: "slider",
              yAxisIndex: 0,
              zoomLock: true,
              width: 8,
              right: 10,
              top: 60,
              bottom: 20,
              start: 0,
              end: endPercentage, // Calculate the zoom percentage to show the right numbers of rows
              showDetail: false,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[800]
                  : theme.palette.neutral[200],
              borderColor: "transparent",
              fillerColor:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[600]
                  : theme.palette.neutral[400],
            },
            {
              type: "inside",
              id: "insideY",
              yAxisIndex: 0,
              start: 0,
              end: endPercentage, // Calculate the zoom percentage to show the right numbers of rows
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
            },
          ]
        : [],
      series: makeSeries(
        wellbores,
        operations,
        colors,
        operationTypeLegendNames,
        theme,
        displayFullLegend
      ),
    };
  }, [
    operations,
    operationTypeLegendNames,
    wellbores,
    theme,
    title,
    hideLegend,
    colors,
    isError,
    tooltipContainer,
    startDate,
    endDate,
  ]);

  const heightOfTheChart = useMemo(() => {
    if (isError || wellbores.length === 0) return 400;
    const numberOfRows = wellbores.length || 0;
    const rowHeight = 40; // Height of each row + 10 padding
    const minHeightChart = 180;
    const maxHeightChart = 460;
    const legendHeight = 65;
    const dynamicHeight = numberOfRows * rowHeight + legendHeight;

    return Math.max(minHeightChart, Math.min(dynamicHeight, maxHeightChart));
  }, [wellbores, isError]);

  echarts.use([
    TooltipComponent,
    LegendComponent,
    CustomChart,
    BarChart,
    CanvasRenderer,
    GridComponent,
  ]);

  const onEvents = {
    click: (
      e: EchartsEventParams<
        [number, Date, Date, OperationTypes, string, string, string]
      >
    ) => {
      onClick(new Event("click"), [e.data[1], e.data[2], e.data[6]]);
    },
    mouseover: (
      e: EchartsEventParams<
        [number, Date, Date, OperationTypes, string, string, string, Event]
      > & { event: { target: { name: string } } }
    ) => {
      const { name: targetName } = e.event.target;
      const echartsInstance = echartsComponentRef.current?.getEchartsInstance();

      const showTooltip = (shouldShow: boolean) => {
        echartsInstance?.setOption({
          tooltip: {
            show: shouldShow,
          },
        });
      };

      if (targetName === "external") {
        showTooltip(false);
        return;
      }

      showTooltip(true);
    },
    legendSelectChanged: (e: {
      name: string;
      selected: Record<string, boolean>;
      type: string;
    }) => {
      const echartsInstance = echartsComponentRef.current?.getEchartsInstance();
      if (!echartsInstance) return;
      const displayFullLegend = false;
      const { selected } = e;
      // Filter the visible operations based on those selected in the legend
      const visibleOperations = operations.filter(
        (op) => selected[operationTypeLegendNames[op.type]]
      );
      // Get the wellbore IDs associated with the visible operations
      const visibleWellboreIds = new Set(
        visibleOperations.flatMap((op) =>
          op.activities.map((activity) => activity.wellboreId)
        )
      );
      // Filter the wellbores to display based on the visible IDs
      const updatedWellbores = removeConsecutiveTitles(
        wellbores.filter(
          (wellbore) => wellbore.isTitle || visibleWellboreIds.has(wellbore.id)
        )
      );

      const updatedSeries = makeSeries(
        updatedWellbores,
        visibleOperations,
        colors,
        operationTypeLegendNames,
        theme,
        displayFullLegend
      );

      echartsInstance.setOption({
        series: updatedSeries,
      });
    },
  };

  if (isLoading) return <ActivityChartLoading />;

  return (
    <Box position="relative">
      {isError && (
        <ActivityChartMessage
          title={t["Error"]}
          description={
            t[
              "We encountered an issue while trying to display your activity schedule. Please refresh the page or try again later."
            ]
          }
          icon={faExclamationCircle}
          iconColor={theme.palette.warning.solidBg}
        />
      )}
      {!isError && wellbores.length === 0 && (
        <ActivityChartMessage
          title={t["Nothing here yet!"]}
          description={
            t[
              "It seems that there are currently no planned activities. They will be displayed here once scheduled."
            ]
          }
          icon={faExclamationCircle}
          iconColor={theme.palette.text.icon}
        />
      )}
      <EChartsRenderer
        ref={echartsComponentRef}
        notMerge={true}
        lazyUpdate={true}
        echarts={echarts}
        option={options}
        onEvents={onEvents}
        style={{
          height: heightOfTheChart,
          width: "100%",
        }}
        opts={{ renderer }}
      />
    </Box>
  );
}

export default forwardRef(AssetActivityChart);

import type { Components, Theme } from "@mui/material/styles";
import { fontSize, fontWeight } from "../../tokens/typography";
import colors from "../../tokens/colors";

const badgeCustomizations: Components<Theme> = {
  MuiBadge: {
    styleOverrides: {
      badge: ({ theme, ownerState }) => ({
        fontWeight: fontWeight.lg,
        fontSize: fontSize.sm,
        ...(!ownerState.badgeContent && {
          height: "0.75rem",
          minWidth: "0.75rem",
          padding: 0,
        }),
        ...(ownerState.color === "info" && {
          backgroundColor: theme.palette.common.white,
          color: colors.grey[800],
        }),
        "& .MuiSvgIcon-root": {
          width: "0.875rem",
          height: "0.875rem",
        },
        "&:has(svg)": {
          padding: 0,
        },
      }),
    },
  },
};

export default badgeCustomizations;

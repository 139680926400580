import type { Components, Theme } from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";

const autocompleteCustomizations: Components<Theme> = {
  JoyAutocomplete: {
    defaultProps: {
      popupIcon: <KeyboardArrowDown />,
      limitTags: 2,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: theme.radius.xl,
        paddingInline: theme.spacing(3),
        boxShadow: "none",
        outlineColor: "transparent",
        "--Autocomplete-wrapperGap": theme.spacing(1),
        "--Icon-fontSize": "1rem",
        "--Icon-color": `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedColor)`,
        "& .MuiAutocomplete-multiple": {
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        "&:not([data-skip-inverted-colors])": {
          "--Input-focusedHighlight":
            theme.vars.palette[ownerState.color || "primary"][500],
        },
        "&.Mui-focused": {
          border: "none",
          "& .MuiAutocomplete-multiple": {
            flexWrap: "nowrap",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          },
        },
        "&.Mui-disabled": {
          svg: {
            color: `var(--joy-palette-${ownerState.color || "primary"}-${ownerState.variant || "soft"}DisabledColor)`,
          },
        },
        "&.Mui-error": {
          ...(ownerState.variant !== "solid" && {
            svg: {
              color: `var(--joy-palette-danger-solidBg)`,
            },
            color: `var(--joy-palette-danger-solidBg)`,
            "--Input-placeholderOpacity": 1,
          }),
        },
        "& .MuiChip-root": {
          ...(ownerState.variant !== "soft" && {
            backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-softBg)`,
          }),
          ...(ownerState.variant === "soft" && {
            backgroundColor: `var(--joy-palette-background-surface)`,
          }),
          minWidth: "auto",
        },
        "& .MuiAutocomplete-limitTag": {
          margin: 0,
          fontWeight: theme.vars.fontWeight.md,
          lineHeight: theme.vars.lineHeight.sm,
        },
        "& .MuiAutocomplete-popupIndicator": {
          "--Icon-color": `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedColor)`,
        },
        ...(ownerState.variant !== "solid" &&
          ownerState.variant !== "soft" && {
            "& .MuiAutocomplete-clearIndicator": {
              "--Icon-color": `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedColor)`,
              "&:hover": {
                "--Icon-color": `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedColor)`,
                backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-softBg)`,
              },
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedColor)`,
              "&:hover": {
                color: `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedColor)`,
                backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-softBg)`,
              },
            },
          }),
        ...(ownerState.variant === "solid" && {
          "--Icon-color": `var(--joy-palette-${ownerState.color ?? "primary"}-solidColor)`,
          "& .MuiAutocomplete-popupIndicator": {
            color: `var(--joy-palette-${ownerState.color ?? "primary"}-solidColor)`,
          },
        }),
        ...(ownerState.variant === "solid" &&
          ownerState.color === "neutral" && {
            boxShadow: theme.vars.shadow.xs,
          }),
        ...(ownerState.variant === "outlined" && {
          backgroundColor: "transparent",
        }),
        ...(ownerState.size === "sm" && {
          paddingInline: theme.spacing(2),
          "--Input-minHeight": "2rem",
        }),
        ...(ownerState.size === "md" && {
          "--Input-minHeight": "2.5rem",
        }),
        ...(ownerState.size === "lg" && {
          "--Input-minHeight": "3rem",
        }),
      }),
      clearIndicator: ({ theme }) => ({
        borderRadius: theme.radius.lg,
      }),
      startDecorator: ({ theme, ownerState }) => ({
        ...(ownerState.size === "sm" && {
          marginLeft: theme.spacing(0.5),
        }),
      }),
      popupIndicator: ({ theme }) => ({
        borderRadius: theme.radius.lg,
      }),
      listbox: ({ theme, ownerState }) => ({
        "--ListDivider-gap": theme.spacing(1),
        border: `1px solid var(--joy-palette-${ownerState.color ?? "primary"}-outlinedBorder)`,
        "--ListItem-paddingY": theme.spacing(1),
        ...(ownerState.size === "sm" && {
          "--ListDivider-gap": theme.spacing(0.5),
          "--ListItem-paddingX": theme.spacing(2),
          maxHeight: "260px",
        }),
        ...(ownerState.size === "md" && {
          "--ListItem-paddingX": theme.spacing(3),
          maxHeight: "300px",
        }),
        ...(ownerState.size === "lg" && {
          "--ListItem-paddingY": theme.spacing(2),
          "--ListItem-paddingX": theme.spacing(4),
          maxHeight: "360px",
        }),
        ...(ownerState.variant === "soft" && {
          backgroundColor: theme.vars.palette.background.surface,
          "& .MuiAutocomplete-option": {
            backgroundColor: theme.vars.palette.background.surface,
            "& .MuiCheckbox-checkbox:hover": {
              backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-500)`,
            },
          },
        }),
      }),
      option: ({ ownerState }) => ({
        color: `var(--joy-palette-${ownerState.color ?? "primary"}-plainHoverColor)`,
        "&:not(.Mui-selected, [aria-selected='true']):hover": {
          backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-softActiveBg)`,
        },
      }),
    },
  },
};

export default autocompleteCustomizations;

import { tooltipClasses, type Components, type Theme } from "@mui/material";
import radius from "../../tokens/radius";
import shadow from "../../tokens/shadow";
import colors from "../../tokens/colors";

const tooltipCustomizations: Components<Theme> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        padding: theme.spacing(2),
        borderRadius: radius.sm,
        boxShadow: shadow.sm,
        backgroundColor: theme.palette.common.white,
        color: colors.grey[800],
        border: `1px solid ${colors.grey[300]}`,
        [`& .${tooltipClasses.arrow}`]: {
          "&:before": {
            border: `1px solid ${colors.grey[300]}`,
            backgroundColor: theme.palette.common.white,
          },
        },
      }),
    },
  },
};

export default tooltipCustomizations;

import type { Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";
import { fontSize } from "../../tokens/typography";
import colors from "../../tokens/colors";

const sliderCustomizations: Components<Theme> = {
  MuiSlider: {
    defaultProps: {},
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const getColorHover = (() => {
          if (!ownerState.color) return theme.palette.info.main;

          const colorMap = {
            primary: colors.blue[600],
            info: colors.grey[300],
            error: colors.red[600],
            success: colors.green[600],
            warning: colors.yellow[600],
            secondary: theme.palette.info.contrastText,
            default: theme.palette.info.contrastText,
          };

          return colorMap[ownerState.color];
        })();

        return {
          color:
            ownerState.color == "info"
              ? theme.palette.common.white
              : theme.palette[ownerState.color || "info"].main,
          height: 8,
          "&:hover": {
            color: getColorHover,
          },
          "&:active": {
            color:
              ownerState.color == "info"
                ? colors.grey[400]
                : theme.palette[ownerState.color || "info"].dark,
          },
          "& .MuiSlider-track": {
            border: "none",
          },
          "& .MuiSlider-thumb": {
            backgroundColor: theme.palette.common.white,
            ...(ownerState.size === "small" && {
              height: 14,
              width: 14,
              border: "4px solid currentColor",
            }),
            ...(ownerState.size === "medium" && {
              height: 16,
              width: 16,
              border: "3px solid currentColor",
            }),
            ...(["info", "warning"].includes(ownerState.color || "") && {
              backgroundColor: colors.grey[800],
            }),
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
              boxShadow: "none",
            },
            "&::before": {
              display: "none",
            },
          },

          "& .MuiSlider-rail": {
            backgroundColor: colors.grey[300],
          },

          "& .MuiSlider-valueLabel": {
            padding: 0,
            paddingInline: "0.25rem",
            ...(ownerState.size === "small" && {
              fontSize: fontSize.sm,
            }),
            ...(ownerState.size === "medium" && {
              fontSize: fontSize.md,
            }),
            backgroundColor: theme.palette.common.white,
            color: colors.grey[900],
            "&::before": {
              width: 5,
              height: 5,
            },
          },
        };
      },
    },
  },
};

export default sliderCustomizations;

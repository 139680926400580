import type { Components, Theme } from "@mui/material/styles";

const menuCustomizations: Components<Theme> = {
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        gap: theme.spacing(1),
        paddingInline: theme.spacing(2),
        "&:hover": {
          backgroundColor: theme.palette.background.default,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.background.default,
          "&:hover": {
            backgroundColor: theme.palette.background.default,
          },
          "&.Mui-focusVisible": {
            backgroundColor: theme.palette.background.default,
          },
        },
        "&.Mui-focusVisible": {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: theme.palette.background.default,
          },
        },
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: ({ theme }) => ({
        top: theme.spacing(1),
      }),
    },
  },
};

export default menuCustomizations;

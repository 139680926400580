import type { Components, Theme } from "@mui/joy";
import { fontWeight } from "../../tokens/typography";

const buttonCustomizations: Components<Theme> = {
  JoyButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => [
        {
          "--Button-radius": theme.radius.xl,
          "--Button-gap": theme.spacing(ownerState.size === "sm" ? 1 : 2),
          fontWeight: fontWeight.md,
          "--Button-paddingBlock": theme.spacing(
            ownerState.size === "lg" ? 2 : 1
          ),
          "--Button-paddingInline": theme.spacing(
            ownerState.size === "lg" ? 4 : 3
          ),
          lineHeight: theme.vars.lineHeight.sm,
        },
        ownerState.size && {
          fontSize: theme.typography[`title-${ownerState.size}`].fontSize,
          ...(ownerState.size === "sm" && {
            "--Button-minHeight": "2rem",
          }),
          ...(ownerState.size === "md" && {
            "--Button-minHeight": "2.5rem",
          }),
          ...(ownerState.size === "lg" && {
            "--Button-minHeight": "3rem",
          }),
        },
        ownerState.variant === "solid" && {
          boxShadow: theme.vars.shadow.xs,
        },
      ],
    },
  },
  JoyIconButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: theme.radius.xl,
        ...(ownerState.size === "sm" && {
          "--Icon-fontSize": "1.25rem",
          "--IconButton-size": "2rem",
        }),
        ...(ownerState.size === "md" && {
          "--Icon-fontSize": "1.5rem",
          "--IconButton-size": "2.5rem",
        }),
        ...(ownerState.size === "lg" && {
          "--Icon-fontSize": "1.875rem",
          "--IconButton-size": "3rem",
        }),
      }),
    },
  },
  JoyMenuButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: theme.radius.lg,
        "--joy-focus-thickness": 0,
        "--Icon-fontSize": "0.875rem",
        ...(ownerState.size === "sm" && {
          "--Button-minHeight": "2rem",
        }),
        ...(ownerState.size === "md" && {
          "--Button-minHeight": "2.5rem",
        }),
        ...(ownerState.size === "lg" && {
          "--Button-minHeight": "3rem",
        }),
        "& .MuiBadge-badge": {
          "--Badge-ring": "transparent",
        },
      }),
    },
  },
};

export default buttonCustomizations;

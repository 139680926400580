import type { Components, Theme } from "@mui/material/styles";
import radius from "../../tokens/radius";
import colors from "../../tokens/colors";

const switchCustomizations: Components<Theme> = {
  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
      size: "medium",
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const isSmall = ownerState.size === "small";
        const isMedium = ownerState.size === "medium";

        const thumbColor = theme.palette.common.white;

        const checkedTranslate = isSmall ? "0.875rem" : "1.125rem";
        const switchWidth = isSmall ? "2.125rem" : "2.625rem";
        const switchHeight = isSmall ? 20 : 24;
        const thumbSize = isSmall ? 14 : 18;

        const bgColor = (() => {
          if (!ownerState.color) return colors.grey[600];
          return {
            primary: colors.blue[600],
            info: colors.grey[200],
            error: colors.red[600],
            success: colors.green[600],
            warning: colors.yellow[600],
            secondary: colors.purple[600],
            default: colors.grey[600],
          }[ownerState.color];
        })();
        const trackColor = (() => {
          if (!ownerState.color) return colors.grey[600];
          return {
            primary: colors.blue[500],
            info: theme.palette.common.white,
            error: colors.red[500],
            success: colors.green[500],
            warning: colors.yellow[500],
            secondary: colors.purple[500],
            default: colors.grey[500],
          }[ownerState.color];
        })();

        return {
          padding: 0,
          display: "flex",
          "& .MuiSwitch-thumb": {
            backgroundColor:
              ownerState.color === "info" ? colors.grey[800] : thumbColor,
            width: thumbSize,
            height: thumbSize,
          },
          "& .MuiSwitch-switchBase": {
            padding: 3,
            "&.Mui-checked": {
              transform: `translateX(${checkedTranslate})`,
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                  ownerState.color === "info"
                    ? theme.palette.common.white
                    : trackColor,
              },
            },
            "&.Mui-checked:hover": {
              backgroundColor: "transparent",
            },
            "&:hover": {
              backgroundColor: "transparent",
              "& +.MuiSwitch-track": {
                backgroundColor: bgColor,
              },
            },
            "&.Mui-disabled": {
              "& +.MuiSwitch-track": {
                backgroundColor:
                  ownerState.color === "info" ? colors.grey[600] : trackColor,
              },
            },
          },
          "& .MuiSwitch-track": {
            borderRadius: radius.xl,
            opacity: 1,
            backgroundColor: trackColor,
            boxSizing: "border-box",
          },
          ...(isSmall && {
            height: switchHeight,
            width: switchWidth,
          }),
          ...(isMedium && {
            height: switchHeight,
            width: switchWidth,
          }),
        };
      },
    },
  },
};

export default switchCustomizations;

import type { Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";
import radius from "../../tokens/radius";

const popoverCustomizations: Components<Theme> = {
  MuiPopover: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiPaper-elevation": {
          backgroundImage: "none",
          border: `1px solid ${theme.palette.info.light}`,
          borderRadius: radius.sm,
        },
      }),
    },
  },
};

export default popoverCustomizations;

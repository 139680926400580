import type { TreeViewBaseItem } from "@mui/x-tree-view";
import { assetFiltersMock } from "./mocks";
import type { RichTreeViewApiRef } from "@mui/x-tree-view/RichTreeView/RichTreeView.types";
import type { UseTreeViewItemsPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewItems/useTreeViewItems.types";

type FilterInfo = {
  label: string;
  index: number;
};

interface TreeViewBaseItemWithParents extends TreeViewBaseItem {
  children?: TreeViewBaseItemWithParents[];
  parents?: string[];
}

export function getItemChildIds(item: TreeViewBaseItemWithParents): string[] {
  const ids: string[] = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemChildIds(child));
  });

  return ids;
}

export function getItemParentIds(
  item: TreeViewBaseItemWithParents,
  api: UseTreeViewItemsPublicAPI<TreeViewBaseItemWithParents>
): string[] {
  const ids: string[] = [];
  item.parents?.forEach((parentId) => {
    ids.push(parentId);
    const parent = api.getItem(parentId);
    ids.push(...getItemParentIds(parent, api));
  });

  return ids;
}

export function getItemsWithParents(
  items: TreeViewBaseItem[],
  parents: string[] = []
): TreeViewBaseItemWithParents[] {
  return items.map((item) => {
    if (item.children) {
      const newParents = [...parents, item.id];
      return {
        ...item,
        parents,
        children: getItemsWithParents(item.children, newParents),
      };
    }

    return {
      ...item,
      parents,
    };
  });
}

export function setSelectedFilterHandler(
  prev: string[],
  id: string,
  data: typeof assetFiltersMock
) {
  if (prev.includes(id)) {
    if (data.areas.some((area) => `area:${area.id}` === id)) {
      return prev.filter((item) => item !== "areas" && item !== id);
    } else if (
      data.operators.some((operator) => `operator:${operator.id}` === id)
    ) {
      return prev.filter((item) => item !== "operators" && item !== id);
    } else if (
      data.assets.some(
        (asset) => `asset:${asset.licenceId}/${asset.fieldId ?? ""}` === id
      )
    ) {
      return prev.filter((item) => item !== "assets" && item !== id);
    }

    return prev.filter((item) => item !== id);
  } else {
    return [...prev, id];
  }
}

export const handleSelectingLogic = (
  checked: boolean,
  itemId: string,
  apiRef: RichTreeViewApiRef,
  selectedFilters: string[] = []
) => {
  if (!apiRef.current) return [];

  // Get the full item, so we have access to the item parents and children
  const item = apiRef.current.getItem(itemId) as TreeViewBaseItem;

  if (checked) {
    const idsToSelect = [itemId];
    // Get item's children
    idsToSelect.push(...getItemChildIds(item));
    // Get item's parents
    idsToSelect.push(...getItemParentIds(item, apiRef.current));

    return [...new Set([...selectedFilters, ...idsToSelect])];
  } else {
    const idsToUnselect = [itemId];
    // Get children
    idsToUnselect.push(...getItemChildIds(item));
    // Get parents
    idsToUnselect.push(...getItemParentIds(item, apiRef.current));

    return selectedFilters.filter((p) => !idsToUnselect.includes(p));
  }
};

export function loadFiltersFromLocalStorage(): string[] {
  const storedFilters = window.localStorage.getItem("POA-selectedFilters");
  return storedFilters ? (JSON.parse(storedFilters) as string[]) : [];
}

export function storeFiltersToLocalStorage(filters: string[]) {
  window.localStorage.setItem("POA-selectedFilters", JSON.stringify(filters));
}

export function convertFiltersToObject(
  selectedFilters: string[],
  data: typeof assetFiltersMock | undefined
) {
  const areas = selectedFilters.filter((filter) =>
    data?.areas.some((area) => `area:${area.id}` === filter)
  );
  const operators = selectedFilters.filter((filter) =>
    data?.operators.some((operator) => `operator:${operator.id}` === filter)
  );
  const assets = selectedFilters.filter((filter) =>
    data?.assets.some(
      (asset) => `asset:${asset.licenceId}/${asset.fieldId ?? ""}` === filter
    )
  );

  return {
    areas: areas.map((area) => area.split(":")[1]),
    operators: operators.map((operator) => operator.split(":")[1]),
    assets: assets.map((asset) => {
      const [licenceId, fieldId] = asset.split(":")[1].split("/");
      return {
        licenceId,
        fieldId: fieldId || null,
      };
    }),
  };
}

export function getMultiSelectFilterData(
  data: typeof assetFiltersMock | undefined,
  type: "assets" | "operators" | "areas"
) {
  if (!data) return [];
  let result = [];
  switch (type) {
    case "assets":
      result = data.assets.map((area) => ({
        id: `asset:${area.licenceId}/${area.fieldId ?? ""}`,
        label: area.field ? `${area.field} (${area.licence})` : area.licence,
      }));
      break;
    case "areas":
      result = data.areas.map((area) => ({
        id: `area:${area.id}`,
        label: area.name,
      }));
      break;
    case "operators":
      result = data.operators.map((operator) => ({
        id: `operator:${operator.id}`,
        label: operator.name,
      }));
      break;
  }
  return result.sort((a, b) => a.label.localeCompare(b.label));
}

export function handleSelectedFiltersText(
  selectedFilters: string[],
  items: TreeViewBaseItem[]
): string {
  const cleanSelectedFilters = selectedFilters.filter(
    (str) => str && typeof str === "string" && str.includes(":")
  );

  if (!cleanSelectedFilters.length) {
    return "";
  }

  const orderedFilters = new Map<string, FilterInfo>();
  items.forEach((item, index) => {
    orderedFilters.set(item.id, { label: item.label, index });
  });

  const selectedFiltersLabels = cleanSelectedFilters
    .map((filter) => {
      const filterInfo = orderedFilters.get(filter);
      return filterInfo
        ? { label: filterInfo.label, index: filterInfo.index }
        : null;
    })
    .filter((item): item is FilterInfo => item !== null)
    .sort((a, b) => a.index - b.index)
    .map((item) => item.label);

  if (selectedFiltersLabels.length > 0) {
    const remainingCount = cleanSelectedFilters.length - 1;
    return remainingCount > 0
      ? `${selectedFiltersLabels[0]}, (+${remainingCount.toString()})`
      : selectedFiltersLabels[0];
  }

  return "";
}

import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "sort";
const width = 20;
const height = 20;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M7.29199 13.4911L8.72505 12.0581C8.96913 11.814 9.36486 11.814 9.60893 12.0581C9.85301 12.3021 9.85301 12.6979 9.60893 12.9419L7.10893 15.4419C6.86486 15.686 6.46913 15.686 6.22505 15.4419L3.72505 12.9419C3.48097 12.6979 3.48097 12.3021 3.72505 12.0581C3.96913 11.814 4.36486 11.814 4.60893 12.0581L6.04199 13.4911V5C6.04199 4.65482 6.32181 4.375 6.66699 4.375C7.01217 4.375 7.29199 4.65482 7.29199 5V13.4911Z M13.9587 6.50888V15C13.9587 15.3452 13.6788 15.625 13.3337 15.625C12.9885 15.625 12.7087 15.3452 12.7087 15V6.50888L11.2756 7.94194C11.0315 8.18602 10.6358 8.18602 10.3917 7.94194C10.1476 7.69786 10.1476 7.30214 10.3917 7.05806L12.8917 4.55806C13.1358 4.31398 13.5315 4.31398 13.7756 4.55806L16.2756 7.05806C16.5197 7.30214 16.5197 7.69786 16.2756 7.94194C16.0315 8.18602 15.6358 8.18602 15.3917 7.94194L13.9587 6.50888Z";
const prefix = "fac";

export const Sort: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Sort);

import type { Components, Theme } from "@mui/material/styles";
import radius from "../../tokens/radius";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { fontSize } from "../../tokens/typography";

const selectCustomizations: Components<Theme> = {
  MuiSelect: {
    defaultProps: {
      native: false,
      IconComponent: KeyboardArrowDown,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: radius.xl,
        ...(ownerState.size === "small" && {
          minHeight: "2rem",
        }),
        ...(ownerState.size === "medium" && {
          minHeight: "2.5rem",
        }),
        "& .MuiSelect-select": {
          padding: 0,
          textAlign: "left",
          paddingRight: theme.spacing(5),
          gap: theme.spacing(1),
          ...(ownerState.size === "small" && {
            fontSize: fontSize.sm,
          }),
          ...(ownerState.size === "medium" && {
            fontSize: fontSize.md,
          }),
          "&:has(svg)": {
            display: "flex",
            alignItems: "center",
          },

          "& .MuiTypography-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },

          "&:after": {
            border: "none",
          },
          "&.Mui-disabled": {
            "-webkit-text-fill-color": theme.palette.action.disabled,
            opacity: 0.7,
          },
        },

        "& .MuiOutlinedInput-notchedOutline ": {
          top: 0,
          "& legend": {
            display: "none",
          },
        },
      }),
    },
  },
};

export default selectCustomizations;

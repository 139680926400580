type Wellbore = {
  id: string;
  name: string;
  isTitle?: boolean;
};
export default function removeConsecutiveTitles(wellbores: Wellbore[]) {
  return wellbores.filter((item, index, array) => {
    if (!item.isTitle) return true;
    if ((array[index + 1] && array[index + 1].isTitle) || !array[index + 1]) {
      return false;
    }
    return true;
  });
}
